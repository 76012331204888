.contact{
    .pdr{
        @include media-breakpoint-up(xl){
            padding-left: 6.25%;
        }
    }
    .txt-ch{
        @include media-breakpoint-down(xs){
            font-size: pxToEm(14);
            letter-spacing: 0.6px;
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(12);
            letter-spacing: 0.6px;
        }
    }
    .txt-en{
        font-size: pxToEm(12.5);
        letter-spacing: 0px;
    }
    .info{
        margin-top: 32px;
        li{
            display: flex;
            margin-bottom: 8px;
        }
        span:nth-child(1){
            width:36px;
            margin-right: 16px;
        }
        a{
            color:$fontcolor;
        }
        .icon{vertical-align: 2px;}
    }
    .coursebtn{
        margin: 36px 0;
    }
}