.design{
    .w1230{
        @include media-breakpoint-up(xl){
            max-width: 1230px;
            margin:0 auto;
        }
    }
    .pgname{
        @include media-breakpoint-up(xl){
            padding-left: 6.25%;
        }
    }
    &.container{
        max-width: 100%;
        @include media-breakpoint-down(md){
            padding:0 25px;
        }
        @include media-breakpoint-up(lg){
            
            padding:0 50px;
        }
    }
    .row.mtemp{
        @include media-breakpoint-down(sm){
            .col{
                margin-bottom: 20px;
                flex:0 0 100%;
                padding-left:25px;
                padding-right: 25px;
            }
        }
        @include media-breakpoint-up(md){
            margin-left: -18px;
            margin-right: -18px;
            margin-bottom: 36px;
            align-items: flex-end;
            .col{
                padding-left:18px;
                padding-right: 18px;
            }
        }
    }
    .mtemp.v1{
        @include media-breakpoint-up(md){
            .col:nth-child(1){
                flex:0 0 48%;
                width:48%;
            }
            .col:nth-child(2){
                flex:0 0 23%;
                width:23%;
            }
            .col:nth-child(3){
                flex:0 0 29%;
                width:29%;
            }
        }
        .col:nth-child(1) .bgcover{
            padding-bottom: 70%;
        }
        .col:nth-child(2) .bgcover{
            padding-bottom: 105%;
        }
        .col:nth-child(3) .bgcover{
            padding-bottom: 115%;
        }
    }
    .mtemp.v2{
        @include media-breakpoint-up(md){
            .col:nth-child(1){
                flex:0 0 35%;
                width:35%;
            }
            .col:nth-child(2){
                flex:0 0 20%;
                width:20%;
            }
            .col:nth-child(3){
                flex:0 0 21%;
                width:21%;
            }
            .col:nth-child(4){
                flex:0 0 24%;
                width:24%;
            }
        }
        .col:nth-child(1) .bgcover{
            padding-bottom: 68%;
        }
        .col:nth-child(2) .bgcover{
            padding-bottom: 140%;
        }
        .col:nth-child(3) .bgcover{
            padding-bottom: 145%;
        }
        .col:nth-child(4) .bgcover{
            padding-bottom: 112%;
        }
    }
    .mtemp.v3{
        @include media-breakpoint-up(md){
            .col:nth-child(1){
                flex:0 0 27%;
                width:48%;
            }
            .col:nth-child(2){
                flex:0 0 33%;
                width:33%;
            }
            .col:nth-child(3){
                flex:0 0 40%;
                width:40%;
            }
        }
        .col:nth-child(1) .bgcover{
            padding-bottom: 140%;
        }
        .col:nth-child(2) .bgcover{
            padding-bottom: 100%;
        }
        .col:nth-child(3) .bgcover{
            padding-bottom: 90%;
        }
    }
}