header{
    @include position(fixed,$top:0,$left:0);
	z-index: 999;
    width:100%;
    transition: background-color 0.6s;
    background-color: rgba(#dbdbdb,1);
    &.menu-expanded{
        background-color: rgba(black,0.9);
    }
    //mmenu
    ul.mmenu{    
		> li{
            > a{
                position: relative;
                display: block;     
                letter-spacing: 1.1px; 
                span:nth-child(1){
                    @extend .fen;
                }
            } 
            @include media-breakpoint-down(md){
                
                padding:0 25px;
                > a{
                    @include font-color(pxToEm(14),white);   
                    line-height: 55px;
                }   
                &.active{
                    background-color: #212121;
                }
            }	
            @include media-breakpoint-up(lg){
                margin-left: 40px;
                > a{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include font-color(pxToEm(11),#999);         
                    line-height: 1; 
                    min-width: 55px;
                    overflow: hidden;
                    text-align:center;	
                    padding:15px 0;
                }
                span{
                    transition: all 0.6s;
                    display: block;
                }
                span:nth-child(2){
                    opacity: 0;
                    @include position(absolute,$top:15px,$left:0);
                    width:100%;
                    text-align: center;
                } 
                &.active > a{
                    color:black;
                }
                &:hover > a{
                    color:black;
                    span:nth-child(1){
                        opacity: 0;
                    }
                    span:nth-child(2){
                        opacity: 1;
                    }
                }
            }
        }
		@include media-breakpoint-up(lg){
            display: flex;
            justify-content:flex-end;
            align-items: center;
        }
	}//end mmenu
    .menuicon{
        position: absolute;
        @include position(absolute, $right:25px, $top:50%);
        transform: translateY(-50%);
		z-index: 10;       
        .mpsrel{
            width:30px;
            height:30px;
        }
        .bar,.bar:before,.bar:after{
            transition: all .25s;
            content: "";
            position: absolute;
            left: 50%;	  
            top:10px;
            height: 2px;
            width: 30px;
            transform: translateX(-50%);
        }
        .bar:before,.bar:after{
            background-color: rgba(black,1);
        }
        .bar:before{top: -6px;}	
        .bar:after{top: 8px;}
		
		@include media-breakpoint-up(lg){
			display: none;
		}
    }
    &.menu-expanded .menuicon .bar{
        background:rgba(white,0);
        &:before{
            top:0px;
            transform: translateX(-50%) rotate(-45deg);
            background-color: white;

        }
        &:after{
            top:0px;
            transform: translateX(-50%) rotate(45deg);
            background-color: white;

        }
    }
    @include media-breakpoint-down(sm){
        
        // .navBox{
        //     height:calc(100vh - 50px);
		// 	-webkit-overflow-scrolling: touch;
        // }
    }
    .logo .sh_about{
        display: none;
    }
    @include media-breakpoint-down(md){  
        // overflow-y:scroll; 
        min-height: 79px;
        .title-bar{
            width:100%;
            @include position(fixed,$top:0px,$left:0);  
            z-index: 99;
            padding:20px 25px;  
        }
        .logo{
            width: 92px;
            height: 39px;
            display: block;
        }    
        .show-lg.logo{
            display: none;
        }
        .navBox{
            display: none;
            width:100%;           
            height:calc(100vh - 79px);
            // @include position(fixed,$top:79px,$left:0);            
            // z-index: 99;     
            margin-top: 79px;   
            // background: rgba(black,0.9);
        }
    }    
    
    @include media-breakpoint-up(lg){
        padding: 25px 50px;
        background: #dbdbdb;
        .title-bar{
            display: none;
        }
        .logo{
            width: 100px;
            height: 42px;
            display: block;
            @include position(absolute,$left:50px,$top:25px);
        }
    }
}
