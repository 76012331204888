.team{
    .container{
        @include media-breakpoint-up(lg){
            max-width: 100%;
            .leftbox{
                height: calc(100vh - 91px);
                padding:0;
                @include position(fixed,$bottom:0px,$left:0);
                .pgname{
                    margin-top: 110px;
                }
                .box{
                    max-width: 600px;
                    padding: 0 15px 0 11%;
                    width: 100%;
                }
                &.change{
                    position: absolute;
                    bottom:100px;
                }
                //overwirte scrll
                .mCSB_inside > .mCSB_container{
                    margin-right: 0;
                }
                .mCSB_container{
                    display: flex;
                    justify-content: flex-end;
                }
                // #mCSB_1_scrollbar_vertical{
                //     opacity: 0 !important;
                // }
                .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
                    background-color: #dbdbdb;
                }
                .mCSB_scrollTools .mCSB_draggerRail,.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
                    background-color: #dbdbdb;
                }
            }
            .offset-lg-6{
                padding: 0;
            }
        }
        
    }
    .mflex{
        display: flex;
        @include media-breakpoint-down(sm){
            margin-bottom: 35px;
        }
        @include media-breakpoint-up(md){            
            margin-bottom: 75px;
        }
    }
    .bgcover{
        padding-bottom: 121%;
    }
    .mimg{
        @include media-breakpoint-down(sm){
            width:45%;
            flex:0 0 45%;
        }
        @include media-breakpoint-up(md){            
            width: 235px;
            flex:0 0 235px;
        }
    }
    .mname{
        background-color: #e9e9e9;  
        letter-spacing: 0.8px;
        text-align: center;
        margin-bottom: 20px;
        transform: translateX(-27px);
        @include media-breakpoint-down(sm){
            width:80px;
            height: 25px;
            padding:5px 0;
            @include font-color(pxToEm(12),black);      
            line-height: 1.2;
            margin-top: 65px;
        }
        @include media-breakpoint-up(md){
            width:130px;
            height: 40px;
            padding:8px 0;
            @include font-color(pxToEm(16),black);      
            line-height: 1.5;
            margin-top: 100px;
        }
    }
    .des{        
        @include media-breakpoint-down(sm){
            letter-spacing: 0px;
            padding-left: 6px;
            .fch{
                @include font-color(pxToEm(14),black);
                margin-bottom: 6px;
            }
            .fen{
                @include font-color(pxToEm(12),#666666,400);
            }
        }
        @include media-breakpoint-up(md){
            letter-spacing: 0.6px;
            padding-left: 12px;
            .fch{
                @include font-color(pxToEm(12),black);
                line-height: 1.5;
                margin-bottom: 6px;
            }
            .fen{
                @include font-color(pxToEm(12),#666666,400);
                line-height: 1.33;
            }
        }
    }
    .editor_Box{
        @include media-breakpoint-down(sm){
            img{
                margin-bottom: 2px;
            }
        }
        @include media-breakpoint-up(md){            
            img{
                margin-bottom: 5px;
            }
        }
        @include media-breakpoint-up(lg){
            max-width: 630px;
            padding-top: 55px;
        }
    }
}