@import url('https://fonts.googleapis.com/css?family=Crimson+Text|Noto+Sans+TC:300,400,700&display=swap');
$mfont:'Noto Sans TC', sans-serif;
.fen{
    font-family: 'Crimson Text', serif;
}
.fmix{
    font-family: 'Crimson Text', 'Noto Sans TC', sans-serif;
}
//-------------------------文字顏色-------------------------//
$pink:#f8756b;
$brwon:#b58a4c;
$blue:#00b4cd;
$orange:#ff6e19;
$yel:#fff100;
$green:#2d4e37;
$gray:#eee;
$fontcolor:#666;

$menu:#999;
$acolor:#104179;


$maxConentW:1200px;
$maxConentPding:15px;
