//overwrite
body{    
    font-family: $mfont;    
    @include font-color(pxToEm(11),#666);
    background-color: #dbdbdb;
    line-height: 1.5;
    letter-spacing: 0.8px;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    &.fade-in{
        opacity: 1;
        transition:opacity  1s ease;
        visibility: visible;
    }
    &.modal-open{
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        height: 100vh;
        // overflow: hidden;
        // position: fixed;
    }
    @include media-breakpoint-down(xs){
        font-size: pxToEm(13);
    }
}
main{   
    @include media-breakpoint-down(xs){
        .container,.col-12{
            padding-left: 25px;
            padding-right: 25px;
        }
        .row{
            margin-right: -25px;
            margin-left: -25px;
        }
    }
    @include media-breakpoint-down(md){
        padding-bottom: 80px;
        padding-top: 110px;
    } 
    @include media-breakpoint-only(lg){
        .container{
            padding-left:50px;
            padding-right: 50px;
        }
    }
    @include media-breakpoint-up(lg){  
        padding-bottom: 100px;
        padding-top: 200px;
        .container{
            max-width: 1200px;
        }
    }
}
.btn_more,.back{
    position: relative;
    display: inline-block;
    letter-spacing: 0.8px;
    width:140px;
    height: 40px;
    padding-left: 30px;
    line-height: 40px;
    letter-spacing: 1.4px;    
    i{
        position: relative;
        width:12px;
        height: 10px;
        margin-left: 5px;
        display: inline-block;
        transition: width 0.6s;
        &:before,&:after{
            content:' ';
            transition: all 0.6s;
        }
        &:before{
            width:100%;
            height: 1px;
            @include position(absolute,$top:50%,$right:0);   
            transform: translateY(-50%);         
        }
        &:after{
            width:6px;
            height: 1px;
            transform: rotate(45deg);
            transform-origin: left;
            @include position(absolute,$top:0,$right:0);
        }
    }
}
.btn_more{
    @include font-color(pxToEm(14),white);
    background-color:black;
    i{
        &:before,&:after{
            background-color: rgba(white,1);
        }
    }
    &:hover{
        color:white;
        i{
            width:18px;
        }
    }
}
.back{
    @include font-color(pxToEm(14),black);
    background-color: rgba(black,0);
    border:1px solid black;    
    i{
        &:before,&:after{
            background-color: rgba(black,1);
        }
    }
    &:hover{
        color:black;
        i{
            width:18px;
        }
    }
}
.pgnametop{
    width:100%;
    flex:0 0 100%;
    @include media-breakpoint-down(sm){
        height: 50px;
        &.hide-sm{
            display: none;
        }
    }
    @include media-breakpoint-up(md){        
        height: 65px;
    }
}
.pgname{
    line-height: 1;
    letter-spacing: 1.8px;
    margin-bottom: 47px;
    @include font-color(pxToEm(18),black,400);
    @extend .fen ;
}
a.coursebtn,#zoom{
    display: block;
    width:140px;
    height: 40px;
    border:1px solid black;
    text-align: center;
    line-height: 40px;
    letter-spacing: 1.4px;
    @include font-color(pxToEm(14),black); 
    @include media-breakpoint-up(md){
        &:hover{
            background-color: black;
            color:white;
        }
    }
}
.bgtop{
    width:100%;
    height: 100px;
    background-color: #dbdbdb;
    @include position(fixed,$top:0,$left:0);
    z-index: 9;
    @include media-breakpoint-down(md){
        display: none;
    }
}
.c_sidebar{  
    @include media-breakpoint-up(xl){
        > div{
            padding-left: 22%;
        }
    }
    &.sticky{
        @include position(fixed,$top:100px);  
        z-index: 10;
    }
    &.change{
        position: absolute;
        bottom: 0;
        top:initial;
        z-index: 10;
    } 
    ul.sidemenu{
        li{
            margin-bottom: 16px;
        }
        li:last-child{
            margin-bottom: 0;
        }
        a{
            line-height: 1;
            display: inline-block;
            letter-spacing: 0.6px;
            @include font-color(pxToEm(12),$fontcolor);
            padding-bottom: 5px;
            border-bottom: 1px solid #dbdbdb;
            transition: all 0.6s;
        }
        a.active{
            border-color:black;
            color:black;
        }
        @include media-breakpoint-down(md){            
            display: none;
        }
        @include media-breakpoint-up(md){
            a:hover{
                border-color:black;
                color:black;
            }
        } 
    }
    
    #top-menu-ul{
        @include media-breakpoint-down(md){

        }
        @include media-breakpoint-up(lg){
            display: none;
        }
    }
}