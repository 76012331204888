.form_des{
    @include font-color(pxToEm(12),black);
    line-height: 2;
    letter-spacing: 0.6px;
    margin-bottom: 50px;
    i{
        color:#a70000;
    } 
    @include media-breakpoint-down(md){
        font-size: pxToEm(14);
        margin-bottom: 40px;
    }
}
form{
    overflow: hidden; 
    letter-spacing: 1.2px;
    input,select,textarea{
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        box-shadow:none;
        width:100%;
        padding:5px;
        border: solid 1px #666666;
        background-color: #dbdbdb;
        @include border(0);
        @include font-color(pxToEm(12),#666);
        &:focus { outline: none; }
    }
    input[type="radio"]{
        @include border(0); 
        padding:0;
    }
    label{
        line-height: 1;
        letter-spacing: 0.6px;
        margin-bottom: 10px;
        @include media-breakpoint-down(sm){
            @include font-color(pxToEm(14), #666);
        }
        @include media-breakpoint-up(md){
            @include font-color(pxToEm(12), #666);
        }
    }
    label i{
        @include media-breakpoint-down(sm){
            @include font-color(pxToEm(14),#ff4444);
        }
        @include media-breakpoint-up(md){
            @include font-color(pxToEm(12),#ff4444);
        }
    }
    &.form-horizontal .form-group{
        display: flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .form-group{
        margin-bottom: 0;
        &.mtop{
            align-items: flex-start;
        }
        &.mcenter{
            align-items: center;
        }
    }
    .with-errors{
        flex:0 0 100%;
        min-height:10px;
        letter-spacing: 0.5px;
        line-height: 1;
        @include font-color(pxToEm(10),#ff4444);  
        display:block;
        .list-unstyled li:before{
            content:'╳';
            transform: scale(0.8);
            display: inline-block;
            margin-right: 2px;
            // width: 10px;
            // height: 10px;
            // background:url("../images/required.png") no-repeat left 1px/8px;
        }
        @include media-breakpoint-down(sm){
            margin:6px 0 15px;
        }
        @include media-breakpoint-up(md){
            margin:6px 0 20px;
        }
    }
    button[type="submit"]{
        background-color: rgba(white,0);
        border:none;
        cursor: pointer;  
    }
    @include media-breakpoint-down(sm){
        input,select{
            height: 40px;
        }
    }
    @include media-breakpoint-up(md){
        input,select{
            height:30px;
        }
    }
    //radio & check
    .flex-check_radi{     
        
        .g_check_radi{
            display: inline-flex;
            flex-wrap: wrap;
        }  
    }
    .check_radi {
        width: auto;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @include media-breakpoint-down(sm){
            @include font-color(pxToEm(14),#666);  
        } 
        @include media-breakpoint-up(md){
            @include font-color(pxToEm(12),#666);  
        }
        line-height: 15px; 
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            left: 0;
            top:0;
            z-index: 5;
        }
        .boxmark {
            @include position(absolute,$top:50%,$right:0);
            transform: translateY(-50%);
            height: 16px;
            width: 16px;
            border:1px solid #666;
            @include border();
        }
        .boxmark:after {
            content: "";
            @include position(absolute,$top:50%,$left:49%);
            transform: translate(-50%,-50%);
            display: none;
            width: 9px;
            height: 9px;
            background: #666;
            @include border();
        }
        input:checked~.boxmark:after {
            display: block;
        }
        .checkmark {
            @include position(absolute,$top:0.3em,$left:0);
            height: 15px;
            width: 15px;
            border:1px solid #666;
        }
        .checkmark:after {
            content: "";
            @include position(absolute,$top:50%,$left:49%);
            transform: translate(-50%,-50%);
            display: none;
            width: 15px;
            height: 15px;
            background: url('../images/agree_yes.png') no-repeat center / 11px ;
        }
        input:checked~.checkmark:after {
            display: block;
        }
    }
    //select
    select::-ms-expand { display: none; }    
    select{
        background:url('../images/select_down.png') no-repeat calc(100% - 12px) 55% / 15px;
        padding:3px 15px 3px 10px ;
        outline: none;
    }
    @media screen\0 {
        select{
        background:url("../images/select_down.png") no-repeat calc(100% - 12px) 55% / 15px;
        }
    }
}
.inquiry{
    .col-12{
        @include media-breakpoint-up(xl){
            flex:0 0 83.33333%;
            max-width: 83.33333%;
            margin-left: 5%;
        }
    }
}
.inquiry form{
    .col-12{
        @include media-breakpoint-up(md){
            display: flex;
        }
    }
    .mflex-1{      
        .form-group{
            @include media-breakpoint-up(md){
                width:calc(33.333% - 10px);
                margin-right: 15px;
            }
        }
        .flex-check_radi label{
            display: block;
            margin-bottom: 10px;
        }
        .g_check_radi{
            padding-left: 6px;
        }
        .check_radi{
            padding-right: 25px;
            @include media-breakpoint-down(sm){
                line-height: 20px;
                margin: 0 15px 25px 0px;
            }
            @include media-breakpoint-up(md){
                line-height: 30px;
                margin: 0 15px 20px 0px;
            }
        }
    }
    .mflex-2 .form-group{
        @include media-breakpoint-up(md){
            width:33.333%;
            &:nth-child(2){
                margin:0 15px;
            }
        }
    }
    .mflex-3{
        label{
            display: block;
            margin-bottom: 24px;
        }
        .flex-check_radi{
            width:100%;
        }
        .g_check_radi{
            display: flex;            
        }
        .g_check_radi > div{
            margin-bottom: 28px;
            @include media-breakpoint-down(sm){
                flex:0 0 100%;
                width:100%;
            }
            @include media-breakpoint-up(md){
                flex:0 0 50%;
                width:50%;
            }
        }
        .check_radi{
            padding: 0 0px 0 25px;  
            line-height: 1.5;
        }
    }
    .mflex-4{
        .form-group{
            width:100%;
        }
        textarea{
            @include media-breakpoint-down(sm){
                height: 175px;
            }
            @include media-breakpoint-up(md){
                height: 300px;
            }
        }
    }
    .the_send{
        border:1px solid #666;
        line-height: 1.71;
        letter-spacing: 1.4px;
        @include font-color(pxToEm(14),#666);
        text-align: center;
        width:140px;
        height: 30px;
        line-height: 27px;
        display: block;
        margin-top: 40px;
        @include media-breakpoint-down(sm){
            line-height: 37px;
            height: 40px;
        }
        @include media-breakpoint-up(md){
            &:hover{
                background: black;
                color:white;
                border-color: black;
            }
        }
    }
}