.indx-banner{
    .indx-menu-sm{
        @include media-breakpoint-up(lg){
            display: none;
        }
        header{
            background-color:rgba(0,0,0,0);
            .menuicon{
                .bar:before,.bar:after{
                    background-color: rgba(white,1);
                }
            }
            &.menu-expanded{
                background-color: rgba(black,0.9);
                .logo .sh_about{
                    opacity: 0;
                }
            }
            .logo img{
                display: none;
            }
            .logo .sh_about{
                display: block;
            }
        }
    }
    .indx-menu-lg{
        @include media-breakpoint-down(md){
            display: none;
        }
        @include position(fixed,$top:25px,$left:50px);
        z-index: 11;
        .logo{
            width:100px;
            height: 42px;
            margin-bottom: 53px;
        }
        ul{
            li{
                margin-bottom: 20px;
                line-height: 9.09px;
                letter-spacing: 1.1px;
            }
            a{
                @include font-color(pxToEm(11),white);
                display: block;
                position: relative;
                span{
                    transition: all 0.6s;
                }
                span:nth-child(1){
                    @extend .fen;
                }
                span:nth-child(2){
                    opacity: 0;
                    @include position(absolute,$top:0,$left:0);
                }
            }
            a:hover{
                span:nth-child(1){
                    opacity: 0;
                }
                span:nth-child(2){
                    opacity: 1;
                }
            }
        }
    }
    
    .slick-slide,.bgcover{
        height: 100vh;
    }
    .slick-slide .bgcover{
        transform: scale(1);
        transition: transform 12s;
    }
    .slickinit .slick-slide.slick-active .bgcover{
        transform: scale(1.1);
    }
    .slickbox a::before{
        content: ' ';
        width:100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        @include position(absolute,$top:0,$left:0);
        z-index: 10;
    }
}