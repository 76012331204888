//common
.course .info{
    h1{
        line-height: 1.67;
        letter-spacing: 0.45px;
        @include font-color(pxToEm(18),black,400);
        &:before{
            content:' ';
            display: block;
            width:30px;
            height: 1px;
            background-color: black;
            margin-bottom: 14px;
        }
    }
    h1 + div{
        line-height: 2.5;
        letter-spacing: 0.6px;
        @include font-color(pxToEm(12),black);
        @include media-breakpoint-down(sm){
            margin-bottom: 35px;
        }
        @include media-breakpoint-up(md){
            margin-bottom: 98px;
        }
    }
    .editor_Box{
        @include media-breakpoint-down(sm){
            margin:15px 0 60px;
        }
        @include media-breakpoint-up(md){            
            margin:30px 0 60px;
        }
    }
}
.course .category{
    @include media-breakpoint-up(xl){
        .pgname{
            padding-left: 10.5%;
        }
        .btn_more{
            margin-left: 10.5%;
        }
    }
    .c_sidebar{
        background-color: #dbdbdb;
        > div{
            padding-left: 0;
        }
    }
    #master{
        background-color: white;
        position: relative;      
        opacity: 0; 
        transition: all 1s;
        .bgcover{
            padding-bottom: 63%;
            opacity: 0;
        }
        .txttop,.txtbtm{
            line-height: 1;
            letter-spacing: 5.4px;
            z-index: 10;
            transition: all 1s;
            transition-delay: .6s;
            opacity: 0;
            @include media-breakpoint-down(md){
                @include font-color(pxToEm(36),black,400); 
            }
            @include media-breakpoint-up(lg){                
                @include font-color(pxToEm(72),black,400); 
            }
        }
        .txttop{
            // @include position(absolute,$top:-0.5em,$right:10px);
            @include position(absolute,$top:-0.5em,$right:20%);
            transform: translateX(50%);
        }
        .txtbtm{
            // @include position(absolute,$bottom:-0.5em,$left:10px);
            @include position(absolute,$bottom:-0.5em,$left:10%);
            // transform: translateX(-50%);
        }
        &.active{
            opacity: 1;
            .txttop,.txtbtm{
                opacity: 1;
            }
            .txttop{
                right:10px;
                transform: translateX(0%);
            }
            .txtbtm{
                left:10px;
                transform: translateX(0%);
            }
        }
    }
    .btn_more{
        @include media-breakpoint-down(md){
            margin-top: 60px;
        }
        @include media-breakpoint-up(lg){
            margin-top: 75px;
            height: 30px;
            line-height: 30px;
        }
    }
    .courseBox{
        display: flex;
        flex-wrap: wrap;
    }
    .cou_name{
        &.pgnametop{
            flex:0 0 100%;
            width:100%; 
        }
        @include media-breakpoint-down(md){
            flex:0 0 100%;
            width:100%;    
            padding:0 0 20px;        
        }
        @include media-breakpoint-up(lg){
            flex:0 0 50%;
            width:50%;
            padding:0 40px 40px;
        }
        a{
            display: block;
            @include media-breakpoint-down(md){
                padding-bottom: 30px;
            }
            @include media-breakpoint-up(lg){
                padding-bottom: 100px;
                &:hover h4:before{
                    width:100%;
                }
            }
        }
        h4{
            line-height: 1.67;
            letter-spacing: 0.45px;
            @include font-color(pxToEm(18),black,400);
            &:before{
                content:' ';
                display: block;
                width:30px;
                height: 1px;
                background-color: black;
                margin-bottom: 14px;
                transition: all 1s;
            }
        }
        h4 + div{
            line-height: 2.5;
            letter-spacing: 0.6px;
            @include font-color(pxToEm(12),black);
        }
        &.active h4:before{
            width:100%;
        }
    }
}
.hd-course{
    @include media-breakpoint-down(md){
        display: none;
    }
}