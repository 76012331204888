/*----------------------通用設定------------------------*/
//清除float浮動
%cf,main[role^=main]  {
    zoom: 1;
    &:before,
    &:after {
        content: "";
        display: table;
        height: 0;
        overflow: hidden;
    }
    &:after {
        clear: both;
    }
}
.clearfix {
  @include clearfix();
}

//selection
p,h1,h2,h3,h4,b,strong,span,li,div,a,img{
    &::selection{
          color:#fff;
          background:#000;
        }
}
//a tag
a{outline: none;}
a,a:hover {
  text-decoration: none;
  transition: 0.4s;
  color:initial;
}
//phone
a[href^=tel] {
  color:$fontcolor;
  &:hover{
    color:$fontcolor;
  }
}
*:focus {
    outline: none;
}
img {
    vertical-align: middle;
    max-width: 100%;
}
ul{
  list-style-type: none;
}
//border-box
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/*----------------------編輯器------------------------*/
.editor_inner {
  line-height: 2.17;
  letter-spacing: 0.6px;
  ul,ol {
      padding-left: 40px;
  }
  img {
      max-width: 100%;
      height: auto !important;
  }
  iframe {
      max-width: 100%;
      @media screen and (max-width: 767px) {
          height: auto !important;
      }
  }
  h1,h2,h3,h4,h5,h6 {
      margin: inherit;
  }
  b,strong{
    font-weight: bold;
  }
  @include media-breakpoint-down(sm){
    @include font-color(pxToEm(14),#666);
  }
  @include media-breakpoint-up(md){
    @include font-color(pxToEm(12),#666);
  }
}
/*----------------------頁籤------------------------*/
.page{
    margin:30px 0 0px;
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    dt a{
      width:8px;
    }
    a{
      font-size: pxToEm(16);
      display: block;
      line-height: 1;
      color:#818181;
    }
    a.active{
      color:$acolor;
    }
     
    @include media-breakpoint-up(md){
      a{
        margin:0 10px;
      }
      dd a:hover{
        color:$acolor;
      }      
    }
    @include media-breakpoint-down(sm){
      justify-content: space-around;
      a{
        font-size: pxToEm(14);
      }
    }
    .nopage{
      opacity: 0;
      z-index: -1;
    }
    
}
/*----------------------go top------------------------*/
#topBtn{
  width:50px;
  // height:50px;
  // line-height: 50px;
  text-align: right;
  display:block;
  z-index:1;
  opacity: 0;
  visibility: hidden;
  transform: rotate(90deg);
  transform-origin: right;
  @extend .fen;
  transition:all 0.6s;
  i{
    &:before,&:after{
      content:' ';
      background-color: rgba(black,1);
      transition: all 0.6s;
    }
    &:before{
      width:30px;
      height: 1px;
      @include position(absolute,$top:0,$right:0);
      @include media-breakpoint-down(xs){
        width:60px;
      }
    }
    &:after{
      width:8px;
      height: 1px;
      transform: rotate(45deg);
      transform-origin: left;
      @include position(absolute,$top:0,$right:22px);
      @include media-breakpoint-down(xs){
        right:50px;
        width:10px;
      }
    }
  }
  &.open{
    opacity: 1;
    visibility: visible;
    z-index:100;
  }
  &.change{
    position: absolute;
  }
  @include media-breakpoint-down(xs){
    @include font-color(pxToEm(15),black);
    @include position(fixed,$bottom:0px,$right:25px);
  }
  @include media-breakpoint-up(sm){
    letter-spacing: 0px;
    @include font-color(pxToEm(12),black);
    @include position(fixed,$bottom:15px,$right:25px);
  }
  @include media-breakpoint-up(md){
    &:hover{
      i:before{
        width:50px;
      }
      i:after{
        right:42px;
      }
    }
  }
}
/*----------------------top-menu-ul------------------------*/
.rowbox.top-menu-row{
  @include media-breakpoint-down(md){
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}
#top-menu-ul{
  @include media-breakpoint-down(md){
    margin:0 auto 35px;
  }
  @include media-breakpoint-up(lg){
    margin:0 auto 50px;
  }
  position: relative;
  z-index: 1;
  .item_Menu{
     margin: 0 auto;   
     overflow: hidden;    
     height: 30px;
  }
  .item_menu_Box{    
      width:100%;
      overflow:hidden;
      overflow-x: scroll;
      position: relative;
      text-align:center;
      .item_menu_list{
          white-space: nowrap !important;
          padding-bottom: 0px;
          text-align: center;
          font-size:0px;
          display:inline-flex; 
      }

      li{
          height: 30px;
      }
      li a{
          position: relative;
          display: block;
          line-height: 1;
          letter-spacing: 0.6px;
          @include font-color(pxToEm(14),$fontcolor);
          border-bottom: 1px solid #dbdbdb;
          @include media-breakpoint-down(md){
            padding:7px 0 3px;
            margin:0 20px 0 0; 
          }
          @include media-breakpoint-up(lg){            
            margin:0 10px; 
            padding:7px 0;
          }
      }
      li.active a{
        border-color:black;
        color:black;
      }
  }
}
.flex-direction-nav{
  display:none;
  a{
      display:block;
      width:18px;   
      height: 18px;
      position: absolute;        
      transform: translateY(-50%);
      top:50%;
      z-index: 10;
      &:after{
          @include position(absolute,$top:0%);
      }
  }
  a.flex-next{
      background: url(../svg/slick-next.svg) no-repeat center / 15px;
      right: -18px;
  }

  a.flex-prev{
      background: url(../svg/slick-prev.svg) no-repeat center / 15px;
      left:  -18px;
  }
}
.open_flexslider{
  .flex-direction-nav{
      display: block;
  }
}
// custom
.text-center{text-align: center;}
.text-right{text-align:right;}
.text-justify{text-align: justify;}
.mpsrel{position: relative;}
.hide{display:none;}
.bgcover{
  width:100%;
  height:0;
  background: center / cover;
}
.rowbox{
  margin:0 -15px 0 -15px;
  position: relative;
  max-width:$maxConentW;
  padding:0 $maxConentPding;
}

@include media-breakpoint-down(md){
  .show-lg{
    display: none;
  }
  .row.col-md-center{
    justify-content: center;
  }
  .bgcover[data-src-lg]{
    content: attr(data-src-lg, url);
  }
}
@include media-breakpoint-up(lg){
  .show-sm{
    display: none;
  }
  .bgcover[data-src-sm]{
    content: attr(data-src-sm, url);
  }
}