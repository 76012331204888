@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
@import url(plug/jquery.fancybox.min.css);
@import url("https://fonts.googleapis.com/css?family=Crimson+Text|Noto+Sans+TC:300,400,700&display=swap");
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* > reset */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

tr,
th,
td {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

.fen, #topBtn, header ul.mmenu > li > a span:nth-child(1), .pgname, .indx-banner .indx-menu-lg ul a span:nth-child(1) {
  font-family: 'Crimson Text', serif; }

.fmix {
  font-family: 'Crimson Text', 'Noto Sans TC', sans-serif; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
main[role^=main] {
  zoom: 1; }
  main[role^=main]:before, main[role^=main]:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role^=main]:after {
    clear: both; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

p::selection, h1::selection, h2::selection, h3::selection, h4::selection, b::selection, strong::selection, span::selection, li::selection, div::selection, a::selection, img::selection {
  color: #fff;
  background: #000; }

a {
  outline: none; }

a, a:hover {
  text-decoration: none;
  transition: 0.4s;
  color: initial; }

a[href^=tel] {
  color: #666; }
  a[href^=tel]:hover {
    color: #666; }

*:focus {
  outline: none; }

img {
  vertical-align: middle;
  max-width: 100%; }

ul {
  list-style-type: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*----------------------編輯器------------------------*/
.editor_inner {
  line-height: 2.17;
  letter-spacing: 0.6px; }
  .editor_inner ul, .editor_inner ol {
    padding-left: 40px; }
  .editor_inner img {
    max-width: 100%;
    height: auto !important; }
  .editor_inner iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_inner iframe {
        height: auto !important; } }
  .editor_inner h1, .editor_inner h2, .editor_inner h3, .editor_inner h4, .editor_inner h5, .editor_inner h6 {
    margin: inherit; }
  .editor_inner b, .editor_inner strong {
    font-weight: bold; }
  @media (max-width: 767.99px) {
    .editor_inner {
      font-size: 0.875rem;
      color: #666;
      font-weight: 300; } }
  @media (min-width: 768px) {
    .editor_inner {
      font-size: 0.75rem;
      color: #666;
      font-weight: 300; } }

/*----------------------頁籤------------------------*/
.page {
  margin: 30px 0 0px;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .page dt a {
    width: 8px; }
  .page a {
    font-size: 1rem;
    display: block;
    line-height: 1;
    color: #818181; }
  .page a.active {
    color: #104179; }
  @media (min-width: 768px) {
    .page a {
      margin: 0 10px; }
    .page dd a:hover {
      color: #104179; } }
  @media (max-width: 767.99px) {
    .page {
      justify-content: space-around; }
      .page a {
        font-size: 0.875rem; } }
  .page .nopage {
    opacity: 0;
    z-index: -1; }

/*----------------------go top------------------------*/
#topBtn {
  width: 50px;
  text-align: right;
  display: block;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transform: rotate(90deg);
  transform-origin: right;
  transition: all 0.6s; }
  #topBtn i:before, #topBtn i:after {
    content: ' ';
    background-color: black;
    transition: all 0.6s; }
  #topBtn i:before {
    width: 30px;
    height: 1px;
    position: absolute;
    top: 0;
    right: 0; }
    @media (max-width: 575.99px) {
      #topBtn i:before {
        width: 60px; } }
  #topBtn i:after {
    width: 8px;
    height: 1px;
    transform: rotate(45deg);
    transform-origin: left;
    position: absolute;
    top: 0;
    right: 22px; }
    @media (max-width: 575.99px) {
      #topBtn i:after {
        right: 50px;
        width: 10px; } }
  #topBtn.open {
    opacity: 1;
    visibility: visible;
    z-index: 100; }
  #topBtn.change {
    position: absolute; }
  @media (max-width: 575.99px) {
    #topBtn {
      font-size: 0.9375rem;
      color: black;
      font-weight: 300;
      position: fixed;
      right: 25px;
      bottom: 0px; } }
  @media (min-width: 576px) {
    #topBtn {
      letter-spacing: 0px;
      font-size: 0.75rem;
      color: black;
      font-weight: 300;
      position: fixed;
      right: 25px;
      bottom: 15px; } }
  @media (min-width: 768px) {
    #topBtn:hover i:before {
      width: 50px; }
    #topBtn:hover i:after {
      right: 42px; } }

/*----------------------top-menu-ul------------------------*/
@media (max-width: 991.99px) {
  .rowbox.top-menu-row {
    margin-left: 0;
    margin-right: 0;
    padding: 0; } }

#top-menu-ul {
  position: relative;
  z-index: 1; }
  @media (max-width: 991.99px) {
    #top-menu-ul {
      margin: 0 auto 35px; } }
  @media (min-width: 992px) {
    #top-menu-ul {
      margin: 0 auto 50px; } }
  #top-menu-ul .item_Menu {
    margin: 0 auto;
    overflow: hidden;
    height: 30px; }
  #top-menu-ul .item_menu_Box {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    text-align: center; }
    #top-menu-ul .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: inline-flex; }
    #top-menu-ul .item_menu_Box li {
      height: 30px; }
    #top-menu-ul .item_menu_Box li a {
      position: relative;
      display: block;
      line-height: 1;
      letter-spacing: 0.6px;
      font-size: 0.875rem;
      color: #666;
      font-weight: 300;
      border-bottom: 1px solid #dbdbdb; }
      @media (max-width: 991.99px) {
        #top-menu-ul .item_menu_Box li a {
          padding: 7px 0 3px;
          margin: 0 20px 0 0; } }
      @media (min-width: 992px) {
        #top-menu-ul .item_menu_Box li a {
          margin: 0 10px;
          padding: 7px 0; } }
    #top-menu-ul .item_menu_Box li.active a {
      border-color: black;
      color: black; }

.flex-direction-nav {
  display: none; }
  .flex-direction-nav a {
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 10; }
    .flex-direction-nav a:after {
      position: absolute;
      top: 0%; }
  .flex-direction-nav a.flex-next {
    background: url(../svg/slick-next.svg) no-repeat center/15px;
    right: -18px; }
  .flex-direction-nav a.flex-prev {
    background: url(../svg/slick-prev.svg) no-repeat center/15px;
    left: -18px; }

.open_flexslider .flex-direction-nav {
  display: block; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.mpsrel {
  position: relative; }

.hide {
  display: none; }

.bgcover {
  width: 100%;
  height: 0;
  background: center / cover; }

.rowbox {
  margin: 0 -15px 0 -15px;
  position: relative;
  max-width: 1200px;
  padding: 0 15px; }

@media (max-width: 991.99px) {
  .show-lg {
    display: none; }
  .row.col-md-center {
    justify-content: center; }
  .bgcover[data-src-lg] {
    content: attr(data-src-lg, url); } }

@media (min-width: 992px) {
  .show-sm {
    display: none; }
  .bgcover[data-src-sm] {
    content: attr(data-src-sm, url); } }

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  transition: background-color 0.6s;
  background-color: #dbdbdb; }
  header.menu-expanded {
    background-color: rgba(0, 0, 0, 0.9); }
  header ul.mmenu > li > a {
    position: relative;
    display: block;
    letter-spacing: 1.1px; }
  @media (max-width: 991.99px) {
    header ul.mmenu > li {
      padding: 0 25px; }
      header ul.mmenu > li > a {
        font-size: 0.875rem;
        color: white;
        font-weight: 300;
        line-height: 55px; }
      header ul.mmenu > li.active {
        background-color: #212121; } }
  @media (min-width: 992px) {
    header ul.mmenu > li {
      margin-left: 40px; }
      header ul.mmenu > li > a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.6875rem;
        color: #999;
        font-weight: 300;
        line-height: 1;
        min-width: 55px;
        overflow: hidden;
        text-align: center;
        padding: 15px 0; }
      header ul.mmenu > li span {
        transition: all 0.6s;
        display: block; }
      header ul.mmenu > li span:nth-child(2) {
        opacity: 0;
        position: absolute;
        top: 15px;
        left: 0;
        width: 100%;
        text-align: center; }
      header ul.mmenu > li.active > a {
        color: black; }
      header ul.mmenu > li:hover > a {
        color: black; }
        header ul.mmenu > li:hover > a span:nth-child(1) {
          opacity: 0; }
        header ul.mmenu > li:hover > a span:nth-child(2) {
          opacity: 1; } }
  @media (min-width: 992px) {
    header ul.mmenu {
      display: flex;
      justify-content: flex-end;
      align-items: center; } }
  header .menuicon {
    position: absolute;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    z-index: 10; }
    header .menuicon .mpsrel {
      width: 30px;
      height: 30px; }
    header .menuicon .bar, header .menuicon .bar:before, header .menuicon .bar:after {
      transition: all .25s;
      content: "";
      position: absolute;
      left: 50%;
      top: 10px;
      height: 2px;
      width: 30px;
      transform: translateX(-50%); }
    header .menuicon .bar:before, header .menuicon .bar:after {
      background-color: black; }
    header .menuicon .bar:before {
      top: -6px; }
    header .menuicon .bar:after {
      top: 8px; }
    @media (min-width: 992px) {
      header .menuicon {
        display: none; } }
  header.menu-expanded .menuicon .bar {
    background: rgba(255, 255, 255, 0); }
    header.menu-expanded .menuicon .bar:before {
      top: 0px;
      transform: translateX(-50%) rotate(-45deg);
      background-color: white; }
    header.menu-expanded .menuicon .bar:after {
      top: 0px;
      transform: translateX(-50%) rotate(45deg);
      background-color: white; }
  header .logo .sh_about {
    display: none; }
  @media (max-width: 991.99px) {
    header {
      min-height: 79px; }
      header .title-bar {
        width: 100%;
        position: fixed;
        top: 0px;
        left: 0;
        z-index: 99;
        padding: 20px 25px; }
      header .logo {
        width: 92px;
        height: 39px;
        display: block; }
      header .show-lg.logo {
        display: none; }
      header .navBox {
        display: none;
        width: 100%;
        height: calc(100vh - 79px);
        margin-top: 79px; } }
  @media (min-width: 992px) {
    header {
      padding: 25px 50px;
      background: #dbdbdb; }
      header .title-bar {
        display: none; }
      header .logo {
        width: 100px;
        height: 42px;
        display: block;
        position: absolute;
        top: 25px;
        left: 50px; } }

footer {
  padding: 20px 25px 50px;
  position: relative; }
  @media (min-width: 992px) {
    footer {
      display: flex;
      align-items: flex-end;
      padding: 20px 50px 25px; } }
  footer .logo {
    width: 100px;
    height: 42px;
    margin-bottom: 20px; }
  footer .info {
    font-size: 0.6875rem;
    color: #666;
    font-weight: 300;
    line-height: 1.82;
    letter-spacing: 0.55px; }
    footer .info span {
      margin-right: 5px; }
    @media (max-width: 767.99px) {
      footer .info span:nth-child(3) {
        display: block; } }
    @media (min-width: 768px) {
      footer .info {
        display: flex; } }
  footer .fright {
    line-height: 20px;
    letter-spacing: 1.1px;
    font-size: 0.6875rem;
    color: #999;
    font-weight: 300; }
    @media (min-width: 992px) {
      footer .fright {
        flex: 0 0 calc(100% - 350px);
        width: calc(100% - 350px);
        display: flex;
        justify-content: flex-end;
        align-items: flex-start; } }
    footer .fright i {
      display: inline-block;
      width: 1px;
      height: 13px;
      background-color: #999;
      margin: 0 6px;
      vertical-align: -2px; }
    footer .fright a {
      color: #999;
      display: inline-block; }
    footer .fright a:hover {
      color: black; }
      footer .fright a:hover .svg path {
        fill: black; }
    footer .fright .svg path {
      fill: #666;
      transition: all 0.6s; }
    footer .fright .fb {
      width: 10px;
      height: 20px; }
    footer .fright .pix {
      width: 49px;
      height: 20px; }
    footer .fright .ig {
      width: 22px;
      height: 20px; }
    footer .fright .icongroup {
      display: flex; }
      footer .fright .icongroup a {
        height: 20px; }
      @media (max-width: 991.99px) {
        footer .fright .icongroup {
          margin-top: 15px; }
          footer .fright .icongroup a {
            margin-right: 40px;
            transform: scale(1.35); } }
      @media (min-width: 992px) {
        footer .fright .icongroup {
          margin-left: 7px; }
          footer .fright .icongroup a {
            margin-left: 18px; } }
  footer a.coursebtn {
    position: absolute;
    top: 20px;
    right: 50px;
    color: black; }
    @media (max-width: 991.99px) {
      footer a.coursebtn {
        right: 25px; } }
    @media (min-width: 768px) {
      footer a.coursebtn:hover {
        background-color: black;
        color: white; } }
  @media (max-width: 991.99px) {
    footer .copyright {
      position: absolute;
      bottom: 17px;
      left: 25px; } }

.lang_tw .en {
  display: none; }

.lang_tw .tw {
  display: block; }

.lang_en .en {
  display: block; }

.lang_en .tw {
  display: none; }

/******************************************/
/*		pages
/******************************************/
body {
  font-family: "Noto Sans TC", sans-serif;
  font-size: 0.6875rem;
  color: #666;
  font-weight: 300;
  background-color: #dbdbdb;
  line-height: 1.5;
  letter-spacing: 0.8px;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden; }
  body.fade-in {
    opacity: 1;
    transition: opacity  1s ease;
    visibility: visible; }
  body.modal-open {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    height: 100vh; }
  @media (max-width: 575.99px) {
    body {
      font-size: 0.8125rem; } }

@media (max-width: 575.99px) {
  main .container, main .col-12 {
    padding-left: 25px;
    padding-right: 25px; }
  main .row {
    margin-right: -25px;
    margin-left: -25px; } }

@media (max-width: 991.99px) {
  main {
    padding-bottom: 80px;
    padding-top: 110px; } }

@media (min-width: 992px) and (max-width: 1199.99px) {
  main .container {
    padding-left: 50px;
    padding-right: 50px; } }

@media (min-width: 992px) {
  main {
    padding-bottom: 100px;
    padding-top: 200px; }
    main .container {
      max-width: 1200px; } }

.btn_more, .back {
  position: relative;
  display: inline-block;
  letter-spacing: 0.8px;
  width: 140px;
  height: 40px;
  padding-left: 30px;
  line-height: 40px;
  letter-spacing: 1.4px; }
  .btn_more i, .back i {
    position: relative;
    width: 12px;
    height: 10px;
    margin-left: 5px;
    display: inline-block;
    transition: width 0.6s; }
    .btn_more i:before, .btn_more i:after, .back i:before, .back i:after {
      content: ' ';
      transition: all 0.6s; }
    .btn_more i:before, .back i:before {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
    .btn_more i:after, .back i:after {
      width: 6px;
      height: 1px;
      transform: rotate(45deg);
      transform-origin: left;
      position: absolute;
      top: 0;
      right: 0; }

.btn_more {
  font-size: 0.875rem;
  color: white;
  font-weight: 300;
  background-color: black; }
  .btn_more i:before, .btn_more i:after {
    background-color: white; }
  .btn_more:hover {
    color: white; }
    .btn_more:hover i {
      width: 18px; }

.back {
  font-size: 0.875rem;
  color: black;
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid black; }
  .back i:before, .back i:after {
    background-color: black; }
  .back:hover {
    color: black; }
    .back:hover i {
      width: 18px; }

.pgnametop {
  width: 100%;
  flex: 0 0 100%; }
  @media (max-width: 767.99px) {
    .pgnametop {
      height: 50px; }
      .pgnametop.hide-sm {
        display: none; } }
  @media (min-width: 768px) {
    .pgnametop {
      height: 65px; } }

.pgname {
  line-height: 1;
  letter-spacing: 1.8px;
  margin-bottom: 47px;
  font-size: 1.125rem;
  color: black;
  font-weight: 400; }

a.coursebtn, #zoom {
  display: block;
  width: 140px;
  height: 40px;
  border: 1px solid black;
  text-align: center;
  line-height: 40px;
  letter-spacing: 1.4px;
  font-size: 0.875rem;
  color: black;
  font-weight: 300; }
  @media (min-width: 768px) {
    a.coursebtn:hover, #zoom:hover {
      background-color: black;
      color: white; } }

.bgtop {
  width: 100%;
  height: 100px;
  background-color: #dbdbdb;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9; }
  @media (max-width: 991.99px) {
    .bgtop {
      display: none; } }

@media (min-width: 1200px) {
  .c_sidebar > div {
    padding-left: 22%; } }

.c_sidebar.sticky {
  position: fixed;
  top: 100px;
  z-index: 10; }

.c_sidebar.change {
  position: absolute;
  bottom: 0;
  top: initial;
  z-index: 10; }

.c_sidebar ul.sidemenu li {
  margin-bottom: 16px; }

.c_sidebar ul.sidemenu li:last-child {
  margin-bottom: 0; }

.c_sidebar ul.sidemenu a {
  line-height: 1;
  display: inline-block;
  letter-spacing: 0.6px;
  font-size: 0.75rem;
  color: #666;
  font-weight: 300;
  padding-bottom: 5px;
  border-bottom: 1px solid #dbdbdb;
  transition: all 0.6s; }

.c_sidebar ul.sidemenu a.active {
  border-color: black;
  color: black; }

@media (max-width: 991.99px) {
  .c_sidebar ul.sidemenu {
    display: none; } }

@media (min-width: 768px) {
  .c_sidebar ul.sidemenu a:hover {
    border-color: black;
    color: black; } }

@media (min-width: 992px) {
  .c_sidebar #top-menu-ul {
    display: none; } }

#lightcase-case {
  padding: 0 15px; }
  #lightcase-case iframe {
    max-width: calc(100vw - 30px) !important; }

#lightcase-overlay {
  background-color: rgba(0, 0, 0, 0.8); }

@media (max-width: 575.99px) {
  a[class*="lightcase-icon-"].lightcase-icon-close {
    width: 20px;
    height: 20px;
    background-size: 20px; } }

@media (min-width: 576px) {
  a[class*="lightcase-icon-"].lightcase-icon-close {
    width: 34px;
    height: 34px;
    background-size: 34px; } }

a[class*="lightcase-icon-"].lightcase-icon-close:before {
  display: none; }

.slickbox {
  position: relative; }
  .slickbox .svg polyline {
    fill: none;
    stroke: #B9B9B9;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    transition: all 0.6s; }
  @media (max-width: 767.99px) {
    .slickbox .svg {
      width: 15px; } }
  @media (min-width: 768px) {
    .slickbox .svg {
      width: 18px; } }
  .slickbox .slickprev, .slickbox .slicknext {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .slickbox .slickprev:after, .slickbox .slicknext:after {
      content: ' ';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 0.6s; }
    .slickbox .slickprev .svg, .slickbox .slicknext .svg {
      position: relative;
      z-index: 2; }
  @media (max-width: 575.99px) {
    .slickbox .slickprev {
      left: -15px; } }
  @media (min-width: 576px) {
    .slickbox .slickprev {
      left: -30px; } }
  @media (min-width: 992px) {
    .slickbox .slickprev {
      width: 56px;
      height: 59px;
      padding: 11px 17px;
      left: -65px; } }
  @media (max-width: 575.99px) {
    .slickbox .slicknext {
      right: -15px; } }
  @media (min-width: 576px) {
    .slickbox .slicknext {
      right: -30px; } }
  @media (min-width: 992px) {
    .slickbox .slicknext {
      width: 58px;
      height: 58px;
      padding: 11px 20px;
      right: -65px; } }
  @media (min-width: 992px) {
    .slickbox a:hover .svg polyline {
      stroke: #ff6e19; } }
  @media (max-width: 575.99px) {
    .slickbox .slick-dots {
      margin-top: -6px; } }
  @media (min-width: 768px) {
    .slickbox .slick-dots {
      margin-top: 12px; } }
  @media (max-width: 575.99px) {
    .slickbox .slick-dots li {
      margin: 0 5px;
      transform: scale(0.85); } }
  .slickbox .slick-dots li button {
    width: 13px;
    height: 13px;
    padding: 0; }
  .slickbox .slick-dots li button::before {
    width: 9px;
    height: 9px;
    background: #ff6e19; }
  .slickbox .slick-dots li.slick-active button::before {
    border: 1px solid #ff6e19;
    background: white;
    transform-origin: center; }
    @media (max-width: 767.99px) {
      .slickbox .slick-dots li.slick-active button::before {
        transform: scale(1.2); } }
    @media (min-width: 768px) {
      .slickbox .slick-dots li.slick-active button::before {
        transform: scale(1.5); } }

.form_des {
  font-size: 0.75rem;
  color: black;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.6px;
  margin-bottom: 50px; }
  .form_des i {
    color: #a70000; }
  @media (max-width: 991.99px) {
    .form_des {
      font-size: 0.875rem;
      margin-bottom: 40px; } }

form {
  overflow: hidden;
  letter-spacing: 1.2px; }
  form input, form select, form textarea {
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    width: 100%;
    padding: 5px;
    border: solid 1px #666666;
    background-color: #dbdbdb;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 0.75rem;
    color: #666;
    font-weight: 300; }
    form input:focus, form select:focus, form textarea:focus {
      outline: none; }
  form input[type="radio"] {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 0; }
  form label {
    line-height: 1;
    letter-spacing: 0.6px;
    margin-bottom: 10px; }
    @media (max-width: 767.99px) {
      form label {
        font-size: 0.875rem;
        color: #666;
        font-weight: 300; } }
    @media (min-width: 768px) {
      form label {
        font-size: 0.75rem;
        color: #666;
        font-weight: 300; } }
  @media (max-width: 767.99px) {
    form label i {
      font-size: 0.875rem;
      color: #ff4444;
      font-weight: 300; } }
  @media (min-width: 768px) {
    form label i {
      font-size: 0.75rem;
      color: #ff4444;
      font-weight: 300; } }
  form.form-horizontal .form-group {
    display: flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
  form .form-group {
    margin-bottom: 0; }
    form .form-group.mtop {
      align-items: flex-start; }
    form .form-group.mcenter {
      align-items: center; }
  form .with-errors {
    flex: 0 0 100%;
    min-height: 10px;
    letter-spacing: 0.5px;
    line-height: 1;
    font-size: 0.625rem;
    color: #ff4444;
    font-weight: 300;
    display: block; }
    form .with-errors .list-unstyled li:before {
      content: '╳';
      transform: scale(0.8);
      display: inline-block;
      margin-right: 2px; }
    @media (max-width: 767.99px) {
      form .with-errors {
        margin: 6px 0 15px; } }
    @media (min-width: 768px) {
      form .with-errors {
        margin: 6px 0 20px; } }
  form button[type="submit"] {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    cursor: pointer; }
  @media (max-width: 767.99px) {
    form input, form select {
      height: 40px; } }
  @media (min-width: 768px) {
    form input, form select {
      height: 30px; } }
  form .flex-check_radi .g_check_radi {
    display: inline-flex;
    flex-wrap: wrap; }
  form .check_radi {
    width: auto;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 15px; }
    @media (max-width: 767.99px) {
      form .check_radi {
        font-size: 0.875rem;
        color: #666;
        font-weight: 300; } }
    @media (min-width: 768px) {
      form .check_radi {
        font-size: 0.75rem;
        color: #666;
        font-weight: 300; } }
    form .check_radi input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0;
      top: 0;
      z-index: 5; }
    form .check_radi .boxmark {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
      border: 1px solid #666;
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em; }
    form .check_radi .boxmark:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 49%;
      transform: translate(-50%, -50%);
      display: none;
      width: 9px;
      height: 9px;
      background: #666;
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em; }
    form .check_radi input:checked ~ .boxmark:after {
      display: block; }
    form .check_radi .checkmark {
      position: absolute;
      top: 0.3em;
      left: 0;
      height: 15px;
      width: 15px;
      border: 1px solid #666; }
    form .check_radi .checkmark:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 49%;
      transform: translate(-50%, -50%);
      display: none;
      width: 15px;
      height: 15px;
      background: url("../images/agree_yes.png") no-repeat center/11px; }
    form .check_radi input:checked ~ .checkmark:after {
      display: block; }
  form select::-ms-expand {
    display: none; }
  form select {
    background: url("../images/select_down.png") no-repeat calc(100% - 12px) 55%/15px;
    padding: 3px 15px 3px 10px;
    outline: none; }
  @media screen\0 {
    form select {
      background: url("../images/select_down.png") no-repeat calc(100% - 12px) 55%/15px; } }

@media (min-width: 1200px) {
  .inquiry .col-12 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: 5%; } }

@media (min-width: 768px) {
  .inquiry form .col-12 {
    display: flex; } }

@media (min-width: 768px) {
  .inquiry form .mflex-1 .form-group {
    width: calc(33.333% - 10px);
    margin-right: 15px; } }

.inquiry form .mflex-1 .flex-check_radi label {
  display: block;
  margin-bottom: 10px; }

.inquiry form .mflex-1 .g_check_radi {
  padding-left: 6px; }

.inquiry form .mflex-1 .check_radi {
  padding-right: 25px; }
  @media (max-width: 767.99px) {
    .inquiry form .mflex-1 .check_radi {
      line-height: 20px;
      margin: 0 15px 25px 0px; } }
  @media (min-width: 768px) {
    .inquiry form .mflex-1 .check_radi {
      line-height: 30px;
      margin: 0 15px 20px 0px; } }

@media (min-width: 768px) {
  .inquiry form .mflex-2 .form-group {
    width: 33.333%; }
    .inquiry form .mflex-2 .form-group:nth-child(2) {
      margin: 0 15px; } }

.inquiry form .mflex-3 label {
  display: block;
  margin-bottom: 24px; }

.inquiry form .mflex-3 .flex-check_radi {
  width: 100%; }

.inquiry form .mflex-3 .g_check_radi {
  display: flex; }

.inquiry form .mflex-3 .g_check_radi > div {
  margin-bottom: 28px; }
  @media (max-width: 767.99px) {
    .inquiry form .mflex-3 .g_check_radi > div {
      flex: 0 0 100%;
      width: 100%; } }
  @media (min-width: 768px) {
    .inquiry form .mflex-3 .g_check_radi > div {
      flex: 0 0 50%;
      width: 50%; } }

.inquiry form .mflex-3 .check_radi {
  padding: 0 0px 0 25px;
  line-height: 1.5; }

.inquiry form .mflex-4 .form-group {
  width: 100%; }

@media (max-width: 767.99px) {
  .inquiry form .mflex-4 textarea {
    height: 175px; } }

@media (min-width: 768px) {
  .inquiry form .mflex-4 textarea {
    height: 300px; } }

.inquiry form .the_send {
  border: 1px solid #666;
  line-height: 1.71;
  letter-spacing: 1.4px;
  font-size: 0.875rem;
  color: #666;
  font-weight: 300;
  text-align: center;
  width: 140px;
  height: 30px;
  line-height: 27px;
  display: block;
  margin-top: 40px; }
  @media (max-width: 767.99px) {
    .inquiry form .the_send {
      line-height: 37px;
      height: 40px; } }
  @media (min-width: 768px) {
    .inquiry form .the_send:hover {
      background: black;
      color: white;
      border-color: black; } }

@media (min-width: 992px) {
  .indx-banner .indx-menu-sm {
    display: none; } }

.indx-banner .indx-menu-sm header {
  background-color: rgba(0, 0, 0, 0); }
  .indx-banner .indx-menu-sm header .menuicon .bar:before, .indx-banner .indx-menu-sm header .menuicon .bar:after {
    background-color: white; }
  .indx-banner .indx-menu-sm header.menu-expanded {
    background-color: rgba(0, 0, 0, 0.9); }
    .indx-banner .indx-menu-sm header.menu-expanded .logo .sh_about {
      opacity: 0; }
  .indx-banner .indx-menu-sm header .logo img {
    display: none; }
  .indx-banner .indx-menu-sm header .logo .sh_about {
    display: block; }

.indx-banner .indx-menu-lg {
  position: fixed;
  top: 25px;
  left: 50px;
  z-index: 11; }
  @media (max-width: 991.99px) {
    .indx-banner .indx-menu-lg {
      display: none; } }
  .indx-banner .indx-menu-lg .logo {
    width: 100px;
    height: 42px;
    margin-bottom: 53px; }
  .indx-banner .indx-menu-lg ul li {
    margin-bottom: 20px;
    line-height: 9.09px;
    letter-spacing: 1.1px; }
  .indx-banner .indx-menu-lg ul a {
    font-size: 0.6875rem;
    color: white;
    font-weight: 300;
    display: block;
    position: relative; }
    .indx-banner .indx-menu-lg ul a span {
      transition: all 0.6s; }
    .indx-banner .indx-menu-lg ul a span:nth-child(2) {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0; }
  .indx-banner .indx-menu-lg ul a:hover span:nth-child(1) {
    opacity: 0; }
  .indx-banner .indx-menu-lg ul a:hover span:nth-child(2) {
    opacity: 1; }

.indx-banner .slick-slide, .indx-banner .bgcover {
  height: 100vh; }

.indx-banner .slick-slide .bgcover {
  transform: scale(1);
  transition: transform 12s; }

.indx-banner .slickinit .slick-slide.slick-active .bgcover {
  transform: scale(1.1); }

.indx-banner .slickbox a::before {
  content: ' ';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10; }

@media (min-width: 1200px) {
  .contact .pdr {
    padding-left: 6.25%; } }

@media (max-width: 575.99px) {
  .contact .txt-ch {
    font-size: 0.875rem;
    letter-spacing: 0.6px; } }

@media (min-width: 576px) {
  .contact .txt-ch {
    font-size: 0.75rem;
    letter-spacing: 0.6px; } }

.contact .txt-en {
  font-size: 0.78125rem;
  letter-spacing: 0px; }

.contact .info {
  margin-top: 32px; }
  .contact .info li {
    display: flex;
    margin-bottom: 8px; }
  .contact .info span:nth-child(1) {
    width: 36px;
    margin-right: 16px; }
  .contact .info a {
    color: #666; }
  .contact .info .icon {
    vertical-align: 2px; }

.contact .coursebtn {
  margin: 36px 0; }

.editor_Box #zoom {
  margin: 50px auto 0; }
  @media (min-width: 768px) {
    .editor_Box #zoom {
      display: none; } }

.modal-content {
  display: none;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10vh 0px 0; }
  .modal-content .modalBox {
    margin: 0 auto;
    padding: 0;
    width: calc(100% - 40px);
    overflow: hidden; }
  .modal-content .mimgbox {
    width: 100%;
    height: 75vh; }
    .modal-content .mimgbox img {
      width: auto;
      height: 70vh;
      max-width: inherit; }
  .modal-content span.close {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    z-index: 10;
    transform: rotate(45deg);
    position: absolute;
    top: 20px;
    right: 20px; }
    .modal-content span.close:after, .modal-content span.close:before {
      content: ' ';
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .modal-content span.close:after {
      width: 2px;
      height: 30px; }
    .modal-content span.close:before {
      width: 30px;
      height: 2px; }
  .modal-content .mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
    margin: 0 12px; }
  .modal-content .mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail, .modal-content .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
    background: #666;
    border-radius: 0px;
    height: 4px; }
  .modal-content .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: #cfcece;
    height: 4px;
    border-radius: 0px; }
  .modal-content .mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
    background: url(../svg/magnifier_left.svg) center no-repeat;
    background-size: 8px;
    width: 8px; }
  .modal-content .mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
    background: url(../svg/magnifier_right.svg) center no-repeat;
    background-size: 8px;
    width: 8px; }

@media (min-width: 992px) {
  .team .container {
    max-width: 100%; }
    .team .container .leftbox {
      height: calc(100vh - 91px);
      padding: 0;
      position: fixed;
      bottom: 0px;
      left: 0; }
      .team .container .leftbox .pgname {
        margin-top: 110px; }
      .team .container .leftbox .box {
        max-width: 600px;
        padding: 0 15px 0 11%;
        width: 100%; }
      .team .container .leftbox.change {
        position: absolute;
        bottom: 100px; }
      .team .container .leftbox .mCSB_inside > .mCSB_container {
        margin-right: 0; }
      .team .container .leftbox .mCSB_container {
        display: flex;
        justify-content: flex-end; }
      .team .container .leftbox .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: #dbdbdb; }
      .team .container .leftbox .mCSB_scrollTools .mCSB_draggerRail, .team .container .leftbox .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #dbdbdb; }
    .team .container .offset-lg-6 {
      padding: 0; } }

.team .mflex {
  display: flex; }
  @media (max-width: 767.99px) {
    .team .mflex {
      margin-bottom: 35px; } }
  @media (min-width: 768px) {
    .team .mflex {
      margin-bottom: 75px; } }

.team .bgcover {
  padding-bottom: 121%; }

@media (max-width: 767.99px) {
  .team .mimg {
    width: 45%;
    flex: 0 0 45%; } }

@media (min-width: 768px) {
  .team .mimg {
    width: 235px;
    flex: 0 0 235px; } }

.team .mname {
  background-color: #e9e9e9;
  letter-spacing: 0.8px;
  text-align: center;
  margin-bottom: 20px;
  transform: translateX(-27px); }
  @media (max-width: 767.99px) {
    .team .mname {
      width: 80px;
      height: 25px;
      padding: 5px 0;
      font-size: 0.75rem;
      color: black;
      font-weight: 300;
      line-height: 1.2;
      margin-top: 65px; } }
  @media (min-width: 768px) {
    .team .mname {
      width: 130px;
      height: 40px;
      padding: 8px 0;
      font-size: 1rem;
      color: black;
      font-weight: 300;
      line-height: 1.5;
      margin-top: 100px; } }

@media (max-width: 767.99px) {
  .team .des {
    letter-spacing: 0px;
    padding-left: 6px; }
    .team .des .fch {
      font-size: 0.875rem;
      color: black;
      font-weight: 300;
      margin-bottom: 6px; }
    .team .des .fen, .team .des #topBtn, .team .des header ul.mmenu > li > a span:nth-child(1), header ul.mmenu > li > a .team .des span:nth-child(1), .team .des .pgname, .team .des .indx-banner .indx-menu-lg ul a span:nth-child(1), .indx-banner .indx-menu-lg ul a .team .des span:nth-child(1) {
      font-size: 0.75rem;
      color: #666666;
      font-weight: 400; } }

@media (min-width: 768px) {
  .team .des {
    letter-spacing: 0.6px;
    padding-left: 12px; }
    .team .des .fch {
      font-size: 0.75rem;
      color: black;
      font-weight: 300;
      line-height: 1.5;
      margin-bottom: 6px; }
    .team .des .fen, .team .des #topBtn, .team .des header ul.mmenu > li > a span:nth-child(1), header ul.mmenu > li > a .team .des span:nth-child(1), .team .des .pgname, .team .des .indx-banner .indx-menu-lg ul a span:nth-child(1), .indx-banner .indx-menu-lg ul a .team .des span:nth-child(1) {
      font-size: 0.75rem;
      color: #666666;
      font-weight: 400;
      line-height: 1.33; } }

@media (max-width: 767.99px) {
  .team .editor_Box img {
    margin-bottom: 2px; } }

@media (min-width: 768px) {
  .team .editor_Box img {
    margin-bottom: 5px; } }

@media (min-width: 992px) {
  .team .editor_Box {
    max-width: 630px;
    padding-top: 55px; } }

@media (min-width: 1200px) {
  .design .w1230 {
    max-width: 1230px;
    margin: 0 auto; } }

@media (min-width: 1200px) {
  .design .pgname {
    padding-left: 6.25%; } }

.design.container {
  max-width: 100%; }
  @media (max-width: 991.99px) {
    .design.container {
      padding: 0 25px; } }
  @media (min-width: 992px) {
    .design.container {
      padding: 0 50px; } }

@media (max-width: 767.99px) {
  .design .row.mtemp .col {
    margin-bottom: 20px;
    flex: 0 0 100%;
    padding-left: 25px;
    padding-right: 25px; } }

@media (min-width: 768px) {
  .design .row.mtemp {
    margin-left: -18px;
    margin-right: -18px;
    margin-bottom: 36px;
    align-items: flex-end; }
    .design .row.mtemp .col {
      padding-left: 18px;
      padding-right: 18px; } }

@media (min-width: 768px) {
  .design .mtemp.v1 .col:nth-child(1) {
    flex: 0 0 48%;
    width: 48%; }
  .design .mtemp.v1 .col:nth-child(2) {
    flex: 0 0 23%;
    width: 23%; }
  .design .mtemp.v1 .col:nth-child(3) {
    flex: 0 0 29%;
    width: 29%; } }

.design .mtemp.v1 .col:nth-child(1) .bgcover {
  padding-bottom: 70%; }

.design .mtemp.v1 .col:nth-child(2) .bgcover {
  padding-bottom: 105%; }

.design .mtemp.v1 .col:nth-child(3) .bgcover {
  padding-bottom: 115%; }

@media (min-width: 768px) {
  .design .mtemp.v2 .col:nth-child(1) {
    flex: 0 0 35%;
    width: 35%; }
  .design .mtemp.v2 .col:nth-child(2) {
    flex: 0 0 20%;
    width: 20%; }
  .design .mtemp.v2 .col:nth-child(3) {
    flex: 0 0 21%;
    width: 21%; }
  .design .mtemp.v2 .col:nth-child(4) {
    flex: 0 0 24%;
    width: 24%; } }

.design .mtemp.v2 .col:nth-child(1) .bgcover {
  padding-bottom: 68%; }

.design .mtemp.v2 .col:nth-child(2) .bgcover {
  padding-bottom: 140%; }

.design .mtemp.v2 .col:nth-child(3) .bgcover {
  padding-bottom: 145%; }

.design .mtemp.v2 .col:nth-child(4) .bgcover {
  padding-bottom: 112%; }

@media (min-width: 768px) {
  .design .mtemp.v3 .col:nth-child(1) {
    flex: 0 0 27%;
    width: 48%; }
  .design .mtemp.v3 .col:nth-child(2) {
    flex: 0 0 33%;
    width: 33%; }
  .design .mtemp.v3 .col:nth-child(3) {
    flex: 0 0 40%;
    width: 40%; } }

.design .mtemp.v3 .col:nth-child(1) .bgcover {
  padding-bottom: 140%; }

.design .mtemp.v3 .col:nth-child(2) .bgcover {
  padding-bottom: 100%; }

.design .mtemp.v3 .col:nth-child(3) .bgcover {
  padding-bottom: 90%; }

.ranking .posfixed {
  background-color: #dbdbdb;
  position: absolute;
  z-index: 10; }
  @media (max-width: 991.99px) {
    .ranking .posfixed.sticky {
      position: fixed;
      top: 78px;
      left: 0;
      padding: 0 25px; } }
  @media (min-width: 992px) {
    .ranking .posfixed.sticky {
      position: fixed;
      top: 100px; } }
  .ranking .posfixed.change {
    position: absolute; }

.ranking h1 {
  line-height: 1.63;
  letter-spacing: 0.8px;
  font-size: 1rem;
  color: black;
  font-weight: 300; }
  @media (max-width: 991.99px) {
    .ranking h1 {
      margin: 0px 0 26px; } }
  @media (min-width: 992px) {
    .ranking h1 {
      margin: 60px 0 26px; } }

.ranking .um_menu {
  display: flex;
  letter-spacing: 0.6px;
  line-height: 1;
  margin-bottom: 25px; }
  .ranking .um_menu a {
    padding-bottom: 5px;
    border-bottom: 1px solid #dbdbdb;
    margin-right: 35px;
    transition: all 0.6s; }
    @media (max-width: 767.99px) {
      .ranking .um_menu a {
        font-size: 0.875rem;
        color: #666;
        font-weight: 300; } }
    @media (min-width: 768px) {
      .ranking .um_menu a {
        font-size: 0.75rem;
        color: #666;
        font-weight: 300; }
        .ranking .um_menu a:hover {
          color: black;
          border-color: black; } }
  .ranking .um_menu a.active {
    color: black;
    border-color: black; }

@media (max-width: 991.99px) {
  .ranking .info {
    margin-top: 105px; } }

@media (min-width: 992px) {
  .ranking .info {
    margin-top: 155px; } }

.ranking .info li {
  position: relative;
  line-height: 2.17;
  letter-spacing: 0.6px; }
  @media (max-width: 767.99px) {
    .ranking .info li {
      font-size: 0.875rem;
      color: #666;
      font-weight: 300;
      padding-right: 50px;
      margin-bottom: 25px;
      line-height: 1.8; }
      .ranking .info li:last-child {
        margin-bottom: 0; } }
  @media (min-width: 768px) {
    .ranking .info li {
      display: flex;
      font-size: 0.75rem;
      color: #666;
      font-weight: 300; } }

@media (min-width: 768px) {
  .ranking .info .mname {
    flex: 0 0 75px;
    width: 75px; } }

.ranking .info .remark {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0; }
  @media (min-width: 768px) {
    .ranking .info .remark {
      flex: 0 0 50px;
      width: 50px; } }

@media (max-width: 991.99px) {
  .ranking #top-menu-ul .item_menu_Box {
    text-align: left; } }

.course .info h1 {
  line-height: 1.67;
  letter-spacing: 0.45px;
  font-size: 1.125rem;
  color: black;
  font-weight: 400; }
  .course .info h1:before {
    content: ' ';
    display: block;
    width: 30px;
    height: 1px;
    background-color: black;
    margin-bottom: 14px; }

.course .info h1 + div {
  line-height: 2.5;
  letter-spacing: 0.6px;
  font-size: 0.75rem;
  color: black;
  font-weight: 300; }
  @media (max-width: 767.99px) {
    .course .info h1 + div {
      margin-bottom: 35px; } }
  @media (min-width: 768px) {
    .course .info h1 + div {
      margin-bottom: 98px; } }

@media (max-width: 767.99px) {
  .course .info .editor_Box {
    margin: 15px 0 60px; } }

@media (min-width: 768px) {
  .course .info .editor_Box {
    margin: 30px 0 60px; } }

@media (min-width: 1200px) {
  .course .category .pgname {
    padding-left: 10.5%; }
  .course .category .btn_more {
    margin-left: 10.5%; } }

.course .category .c_sidebar {
  background-color: #dbdbdb; }
  .course .category .c_sidebar > div {
    padding-left: 0; }

.course .category #master {
  background-color: white;
  position: relative;
  opacity: 0;
  transition: all 1s; }
  .course .category #master .bgcover {
    padding-bottom: 63%;
    opacity: 0; }
  .course .category #master .txttop, .course .category #master .txtbtm {
    line-height: 1;
    letter-spacing: 5.4px;
    z-index: 10;
    transition: all 1s;
    transition-delay: .6s;
    opacity: 0; }
    @media (max-width: 991.99px) {
      .course .category #master .txttop, .course .category #master .txtbtm {
        font-size: 2.25rem;
        color: black;
        font-weight: 400; } }
    @media (min-width: 992px) {
      .course .category #master .txttop, .course .category #master .txtbtm {
        font-size: 4.5rem;
        color: black;
        font-weight: 400; } }
  .course .category #master .txttop {
    position: absolute;
    top: -0.5em;
    right: 20%;
    transform: translateX(50%); }
  .course .category #master .txtbtm {
    position: absolute;
    bottom: -0.5em;
    left: 10%; }
  .course .category #master.active {
    opacity: 1; }
    .course .category #master.active .txttop, .course .category #master.active .txtbtm {
      opacity: 1; }
    .course .category #master.active .txttop {
      right: 10px;
      transform: translateX(0%); }
    .course .category #master.active .txtbtm {
      left: 10px;
      transform: translateX(0%); }

@media (max-width: 991.99px) {
  .course .category .btn_more {
    margin-top: 60px; } }

@media (min-width: 992px) {
  .course .category .btn_more {
    margin-top: 75px;
    height: 30px;
    line-height: 30px; } }

.course .category .courseBox {
  display: flex;
  flex-wrap: wrap; }

.course .category .cou_name.pgnametop {
  flex: 0 0 100%;
  width: 100%; }

@media (max-width: 991.99px) {
  .course .category .cou_name {
    flex: 0 0 100%;
    width: 100%;
    padding: 0 0 20px; } }

@media (min-width: 992px) {
  .course .category .cou_name {
    flex: 0 0 50%;
    width: 50%;
    padding: 0 40px 40px; } }

.course .category .cou_name a {
  display: block; }
  @media (max-width: 991.99px) {
    .course .category .cou_name a {
      padding-bottom: 30px; } }
  @media (min-width: 992px) {
    .course .category .cou_name a {
      padding-bottom: 100px; }
      .course .category .cou_name a:hover h4:before {
        width: 100%; } }

.course .category .cou_name h4 {
  line-height: 1.67;
  letter-spacing: 0.45px;
  font-size: 1.125rem;
  color: black;
  font-weight: 400; }
  .course .category .cou_name h4:before {
    content: ' ';
    display: block;
    width: 30px;
    height: 1px;
    background-color: black;
    margin-bottom: 14px;
    transition: all 1s; }

.course .category .cou_name h4 + div {
  line-height: 2.5;
  letter-spacing: 0.6px;
  font-size: 0.75rem;
  color: black;
  font-weight: 300; }

.course .category .cou_name.active h4:before {
  width: 100%; }

@media (max-width: 991.99px) {
  .hd-course {
    display: none; } }

@media (max-width: 767.99px) {
  .about .editor_Box {
    margin: 0px 0 35px; } }

@media (min-width: 768px) {
  .about .editor_Box {
    margin: 150px 0 90px; } }

@media (max-width: 767.99px) {
  .about .slides li > div {
    width: 100vw; } }

@media (min-width: 768px) {
  .about .slides li > div {
    width: calc(100vw / 3 - 8px);
    margin-right: 8px; } }

.about .slides li:last-child > div {
  margin-right: 0; }

.about .slides .bgcover {
  padding-bottom: 62%; }

@media (min-width: 1200px) {
  .about .col-12 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: 5%; } }

.about #top-menu-ul {
  margin-bottom: 0; }

.about #top-menu-ul .item_Menu, .about #top-menu-ul .item_menu_Box li {
  height: auto; }

.about #top-menu-ul .item_menu_Box {
  overflow: hidden; }

.about .flex-direction-nav {
  display: block; }
  .about .flex-direction-nav a {
    width: 50px;
    height: 50px;
    transform: translateY(-30px); }
  .about .flex-direction-nav a.flex-next {
    background: url(../svg/slick-next-w.svg) #333333 no-repeat center/15px;
    right: 0px; }
  .about .flex-direction-nav a.flex-prev {
    background: url(../svg/slick-prev-w.svg) #333333 no-repeat center/15px;
    left: 0px; }

.about .mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0; }

.about .mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #eee; }

.about .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: black; }
