.editor_Box{
    #zoom{
        margin:50px auto 0;
        @include media-breakpoint-up(md){
            display: none;
        }
    }
}

.modal-content{   
    display: none;
    width:100%;
    height:100vh;
    z-index: 1000;
    @include position(fixed,$left:0%,$top:0%);    
    background-color:rgba(black,0.9);
    padding: 10vh 0px 0;
    .modalBox{
        margin:0 auto;
        padding:0;
        width:calc(100% - 40px);
        overflow: hidden;
    }
    .mimgbox{
        width:100%;
        height: 75vh;
        
        img{
            width:auto;
            height: 70vh;
            max-width: inherit;
        }
    }
    span.close{
        display:inline-block;
        width:35px;
        height:35px;
        cursor: pointer;
        z-index: 10;
        transform: rotate(45deg);
        @include position(absolute,$top:20px,$right:20px);
        &:after,&:before{
            content:' ';
            background-color: white;
            @include position(absolute,$left:50%,$top:50%);
            transform: translate(-50%,-50%);
        }
        &:after{            
            width:2px;
            height: 30px;
        }
        &:before{
            width:30px;
            height: 2px;
        }
    }
    //scroll
    //overwrite
    .mCSB_scrollTools.mCSB_scrollTools_horizontal a+.mCSB_draggerContainer{
        margin: 0 12px;
    }
	.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail{
		background:#666;
        border-radius: 0px;
        height: 4px;
	}
	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
        background:#cfcece;
        height: 4px;
        border-radius: 0px;
	}
	.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft{
		background: url(../svg/magnifier_left.svg) center no-repeat;
        background-size:8px;
        width:8px;
	}
	.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight{
		background: url(../svg/magnifier_right.svg) center no-repeat;
        background-size:8px;
        width:8px;
	}
    // @include media-breakpoint-down(md){
    //     max-width:85%;
    //     max-height:90%;
    //     height: auto;
    //     .modalBox{
    //         overflow-y:auto;
    //         -webkit-overflow-scrolling: touch;
    //     }
    // }
}