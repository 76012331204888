footer{    
    padding:20px 25px 50px;
    position: relative;
    @include media-breakpoint-up(lg){
        display: flex;
        align-items: flex-end;
        padding:20px 50px 25px;
    }
    .logo{
        width: 100px;
        height: 42px;
        margin-bottom: 20px;
    }
    .info{
        @include font-color(pxToEm(11),#666);
        line-height: 1.82;
        letter-spacing: 0.55px;
        span{
            margin-right: 5px;
        }
        @include media-breakpoint-down(sm){
            span:nth-child(3){
                display: block;
            }
        }
        @include media-breakpoint-up(md){
            display: flex;
        }
    }
    .fright{
        @include media-breakpoint-up(lg){
            flex:0 0 calc(100% - 350px);
            width:calc(100% - 350px);
            display: flex;
            justify-content:flex-end;
            align-items: flex-start;
        }
        line-height: 20px;
        letter-spacing: 1.1px;
        @include font-color(pxToEm(11),#999);
        i{
            display: inline-block;
            width:1px;
            height: 13px;
            background-color:#999;
            margin:0 6px;
            vertical-align: -2px;
        }
        a{
            color:#999;
            display: inline-block;
        }
        a:hover{
            color:black;
            .svg path{
                fill: black;
            }
        }
        .svg{
            path{
                fill:#666;
                transition: all 0.6s;
            }
        }
        .fb{
            width:10px;
            height: 20px;
        }
        .pix{
            width:49px;
            height: 20px;
        }
        .ig{
            width:22px;
            height: 20px;
        }
        .icongroup{
            display: flex;
            a{
                height: 20px;
            }
            @include media-breakpoint-down(md){
                margin-top: 15px;
                a{
                    margin-right: 40px;
                    transform: scale(1.35);
                }
            }
            @include media-breakpoint-up(lg){
                margin-left: 7px;
                a{
                    margin-left:18px;
                }
            }
        }
    }
    a.coursebtn{
        @include position(absolute,$right:50px,$top:20px);
        color:black;
        @include media-breakpoint-down(md){
            right:25px;
        }
        @include media-breakpoint-up(md){
            &:hover{
                background-color: black;
                color:white;
            }
        }
    }
    .copyright{
        @include media-breakpoint-down(md){
            @include position(absolute,$bottom:17px,$left:25px);
        }
    }
}