.about{
    .editor_Box{
        @include media-breakpoint-down(sm){
            margin:0px 0 35px;
        }
        @include media-breakpoint-up(md){
            margin:150px 0 90px;
        }
    }
    .slides{
        li > div{
            @include media-breakpoint-down(sm){
                width:100vw;
            }
            @include media-breakpoint-up(md){
                width:calc(100vw / 3 - 8px);
                margin-right: 8px;                
            }
        }
        li:last-child > div{
            margin-right: 0;
        }
        .bgcover{
            padding-bottom: 62%;
        }
    }
    .col-12{
        @include media-breakpoint-up(xl){
            flex:0 0 83.33333%;
            max-width: 83.33333%;
            margin-left: 5%;
        }
    }
    //overwrite
    #top-menu-ul{
        margin-bottom: 0;
    }
    #top-menu-ul .item_Menu,#top-menu-ul .item_menu_Box li{
        height: auto;
    }
    #top-menu-ul .item_menu_Box{
        overflow: hidden;
    }
    .flex-direction-nav{
        display: block;
        a{
            width:50px;
            height: 50px;
            transform: translateY(-30px);
        }
        a.flex-next{
            background: url(../svg/slick-next-w.svg) #333333 no-repeat center / 15px;
            right: 0px;
        }
      
        a.flex-prev{
            background: url(../svg/slick-prev-w.svg) #333333 no-repeat center / 15px;
            left:  0px;
        }
    }
    .mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer{
        margin: 0;
    }
    .mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail{
        background-color: #eee;
    }
    .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
        background-color:black; 
    }
}