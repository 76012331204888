.ranking{
    .posfixed{
        background-color: #dbdbdb;
        position:absolute;
        z-index: 10;
        &.sticky{
            @include media-breakpoint-down(md){
                @include position(fixed,$top:78px,$left:0);
                padding: 0 25px;
            }
            @include media-breakpoint-up(lg){                
                @include position(fixed,$top:100px);
            }
        }
        &.change{
            position:absolute;
        }
    }
    h1{
        line-height: 1.63;
        letter-spacing: 0.8px;
        @include font-color(pxToEm(16),black);
        @include media-breakpoint-down(md){            
            margin:0px 0 26px;
        }
        @include media-breakpoint-up(lg){
            margin:60px 0 26px;
        }
    }
    .um_menu{
        display: flex;
        letter-spacing: 0.6px;
        line-height: 1;
        margin-bottom: 25px;
        a{
            padding-bottom: 5px;
            border-bottom:1px solid #dbdbdb;
            margin-right: 35px;
            transition: all 0.6s;
            @include media-breakpoint-down(sm){                
                @include font-color(pxToEm(14),#666);
            }
            @include media-breakpoint-up(md){            
                @include font-color(pxToEm(12),#666);
                &:hover{
                    color:black;
                    border-color:black;
                }
            }
        }
        a.active{
            color:black;
            border-color:black;
        }
        
    }
    .info{
        @include media-breakpoint-down(md){            
            margin-top: 105px;
        }
        @include media-breakpoint-up(lg){
            margin-top: 155px;
        }
        li{
            position: relative;
            line-height: 2.17;
            letter-spacing: 0.6px;        
            @include media-breakpoint-down(sm){
                @include font-color(pxToEm(14),#666);
                padding-right: 50px;
                margin-bottom: 25px;
                line-height: 1.8;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            @include media-breakpoint-up(md){
                display: flex;
                @include font-color(pxToEm(12),#666);
            }
        }
        .mname{
            @include media-breakpoint-up(md){
                flex:0 0 75px;
                width:75px;
            }
        }
        .remark{
            text-align: right;
            @include position(absolute,$right:0,$top:0);
            @include media-breakpoint-up(md){
                flex:0 0 50px;
                width:50px;
            }
        }
    }
    @include media-breakpoint-down(md){
        #top-menu-ul .item_menu_Box{
            text-align: left;
        }
    }
}